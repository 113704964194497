import Checkbox from '@/components/checkbox';
import { OpenQuestion } from '@/lib/interface-types';
import AddBlue from '@/public/icons/AddBlue.svg';
import CvIcon from '@/public/icons/CvIcon.svg';
import Close from '@/public/icons/close-green.svg';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';
import Spinner from './spinner';

interface FormData {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  multi_content: string;
  text: string;
  uploadFile: File;
  radio: string;
}

interface ErrorObjectIC {
  [key: string]: string;
}

interface JobFormProps {
  id: string;
  onClose: () => void;
  slug: string;
  position?: string;
  questions?: OpenQuestion[];
}

const JobForm = ({ id, onClose, position, slug, questions }: JobFormProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errors, setErrors] = useState<ErrorObjectIC>({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    uploadFile: '',
    radio: '',
    selectedValues: '',
    description: ''
  });
  const { locale } = useRouter();
  const [formValidError, setFormValidError] = useState<string | null>(null);
  const [sppiner, setSppiner] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const { t } = useTranslation();

  const validateForm = (formData: FormData) => {
    const newErrors: ErrorObjectIC = {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      uploadFile: '',
      radio: '',
      selectedValues: '',
      description: ''
    };

    if (!formData.firstname) {
      newErrors.firstname = ' ';
    } else if (
      formData.firstname.length < 2 ||
      !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/.test(formData.firstname)
    ) {
      newErrors.firstname = t('errorMessages.invalidFirstName');
    }
    if (!formData.lastname) {
      newErrors.lastname = ' ';
    } else if (
      formData.lastname.length < 2 ||
      !/^[A-Za-zÀ-ÖØ-öø-ÿ ]+$/.test(formData.lastname)
    ) {
      newErrors.lastname = t('errorMessages.invalidLastName');
    }
    if (!formData.phone) {
      newErrors.phone = ' ';
    } else if (!/^\+?\d+$/.test(formData.phone)) {
      newErrors.phone = t('errorMessages.invalidPhone');
    }
    if (!formData.email) {
      newErrors.email = ' ';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('errorMessages.invalidEmail');
    }
    if (!!questions?.length) {
      if (!formData.multi_content) {
        newErrors.radio = t('errorMessages.selectAtLeastOne');
      }
      if (!formData.text) {
        newErrors.radio = t('errorMessages.enterDescription');
      }
    }
    if (!formData.uploadFile) {
      newErrors.uploadFile = 'Please upload CV';
    }
    if (!formData.radio) {
      newErrors.radio = 'Please select an option';
    }

    setErrors(newErrors);

    const valid = Object.values(newErrors).every((error) => error === '');
    return valid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const fetchBody = new FormData();
    const formValid = validateForm(Object.fromEntries(formData) as any);

    if (!formValid || !selectedFile) {
      setFormValidError(t('errorMessages.mandatoryErrorMessage'));
      return;
    }

    fetchBody.append('candidate[cv]', selectedFile);
    fetchBody.append(
      'candidate[name]',
      `${formData.get('firstname')} ${formData.get('lastname')}`
    );
    fetchBody.append('candidate[phone]', `${formData.get('phone')}`);
    fetchBody.append('candidate[email]', `${formData.get('email')}`);

    if (questions && !!questions.length) {
      const multiChoiceQuestions = questions.filter(
        (q) => q.kind === 'multi_choice'
      )[0];

      const description = questions.filter((q) => q.kind === 'text')[0];

      const checkboxes = event.currentTarget.querySelectorAll(
        'input[name="multi_content"]:checked'
      );

      fetchBody.append(
        `candidate[open_question_answers_attributes][${multiChoiceQuestions.position}][open_question_id]`,
        `${multiChoiceQuestions.id}`
      );

      checkboxes.forEach((checkbox: HTMLInputElement, i: number) => {
        fetchBody.append(
          `candidate[open_question_answers_attributes][${multiChoiceQuestions.position}][multi_content][${i}]`,
          checkbox.value
        );
      });

      fetchBody.append(
        `candidate[open_question_answers_attributes][${description.position}][open_question_id]`,
        `${description.id}`
      );
      fetchBody.append(
        `candidate[open_question_answers_attributes][${description.position}][content]`,
        `${formData.get('text')}`
      );
    }

    setSppiner(true);

    const response = await fetch(
      `https://consulteer1.recruitee.com/api/offers/${slug}/candidates`,
      {
        method: 'POST',
        headers: {
          accept: 'application/json'
        },
        body: fetchBody
      }
    );

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    if (response.ok) {
      form.reset();
      setErrors({});
      setSppiner(false);
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setUploadedFileName(file?.name);
  };

  let fileName = t('jobForm.cv');
  if (selectedFile && 'name' in selectedFile) {
    fileName = (selectedFile as File).name;
  }

  return (
    <div className="fixed top-0 left-0 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 z-[999]">
      <div className="bg-white p-10 overflow-auto sm:w-full lg:w-1/2 sm:mx-6 lg:mx-20 h-full lg:max-h-[800px] overflow-auto">
        <div className="flex w-full justify-end text-gray-900">
          <Image
            src={Close}
            alt="Close"
            width={30}
            height={30}
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="flex flex-col text-base">
          <h1 className="text-4xl lg:text-[3rem]  mb-2">
            Join the <span className="text-brand-green">Extra</span>ordinary
          </h1>
          <p className="my-8" style={{ color: 'black' }}>
            {t('jobForm.subtitle')}
          </p>

          {position && <h5 className="m-0 mb-5">{position}</h5>}
          <form
            onSubmit={handleSubmit}
            className="sm:w-full lg:w-5/6 bg-white flex flex-col"
          >
            <div className="flex flex-col leading-[60px]">
              <input
                type="text"
                name="firstname"
                placeholder={t('jobForm.firstName') || ''}
                className="text-black border-b border-black min-w-350 outline-none"
              />
              {errors.firstname && (
                <span className="text-red-500 leading-loose">
                  {errors.firstname}
                </span>
              )}
              <input
                type="text"
                name="lastname"
                placeholder={t('jobForm.lastName') || ''}
                className="text-black border-b border-black min-w-350 outline-none"
              />
              {errors.lastname && (
                <span className="text-red-500 leading-loose">
                  {errors.lastname}
                </span>
              )}
              <input
                type="email"
                name="email"
                placeholder="Email*"
                className="text-black border-b border-black min-w-350 outline-none"
              />
              {errors.email && (
                <span className="text-red-500 leading-loose">
                  {errors.email}
                </span>
              )}
              <input
                type="text"
                name="phone"
                placeholder={t('jobForm.phone') || ''}
                className="text-black border-b border-black min-w-350 outline-none"
              />
              {errors.phone && (
                <span className="text-red-500 leading-loose">
                  {errors.phone}
                </span>
              )}
              <input type="hidden" name="jobId" value={id} />
            </div>
            <div
              className="flex text-left mt-6 cursor-pointer"
              onClick={() => {
                const fileInput = document.getElementById(
                  'filecv'
                ) as HTMLInputElement | null;
                fileInput?.click();
              }}
              aria-hidden="true"
            >
              <Image
                src={selectedFile ? CvIcon : AddBlue}
                alt="CV Icon"
                width={24}
                height={24}
                className="cursor-pointer"
              />
              <div className="ml-4 mt-1 text-black">{fileName}</div>
              <input
                type="file"
                id="filecv"
                name="uploadFile"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {errors.uploadFile && (
                <span className="text-red-500 leading-loose">
                  {errors.uploadFile}
                </span>
              )}
            </div>
            <div className="flex flex-col mt-11">
              {questions &&
                questions.map((q) => (
                  <Fragment key={q.id}>
                    {q.kind === 'multi_choice' ? (
                      <>
                        <p className="mb-4">
                          {t('jobForm.selectValues')}
                          <span className="block">
                            ({t('jobForm.multipleChoice')})
                          </span>
                        </p>
                        <div className="flex flex-col gap-x-4 pl-4">
                          {q.open_question_options.map((o) => {
                            return (
                              <Checkbox
                                key={o.position}
                                name="multi_content"
                                labelText={o.body.replace('&amp;', '&')}
                                position={o.position}
                                value={o.body}
                              />
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      q.kind === 'text' && (
                        <div className="flex flex-col">
                          <p className="text-black my-7">
                            {t('jobForm.explain')}*
                          </p>
                          <textarea
                            className="border border-grey-dark py-4 px-11 h-48 focus:outline-none"
                            placeholder={`${t('contactForm.typeDescription')}`}
                            name={q.kind}
                          />
                        </div>
                      )
                    )}
                  </Fragment>
                ))}
            </div>
            <div className={`${styles.checkBox} flex`}>
              <input type="checkbox" name="radio" id="read" />
              <label htmlFor="read" className="text-gray-700 font-sans flex">
                <input type="checkbox" name="radio" id="read" />

                <span style={{ color: 'black' }}>
                  <div className="max-w-sm">
                    {locale === 'en-US' && (
                      <>
                        {t('jobForm.haveRead')}
                        <Link className="text-black underline" href="/privacy">
                          {t('jobForm.privacyPolicy')}
                        </Link>
                      </>
                    )}
                    {locale === 'de' && (
                      <>
                        <Link className="text-black underline" href="/privacy">
                          {t('jobForm.privacyPolicy')}
                        </Link>
                        {t('jobForm.haveRead')}
                      </>
                    )}
                  </div>
                </span>
              </label>
            </div>
            {formValidError && (
              <div className="mb-4">
                <span className="text-red-500">{formValidError}</span>
              </div>
            )}
            <div className="flex">
              {!sppiner && (
                <button className="green-button" type="submit">
                  <p>{t('jobForm.apply')}</p>
                </button>
              )}
              {sppiner && <Spinner />}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
